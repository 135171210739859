<template>
  <div
    style="width: 100%; height: 100vh"
    v-loading="loading"
    element-loading-text="加载中，请稍后"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  ></div>
</template>
  <script>
export default {
  data() {
    return {
      loading: true,
      userName: '',
      flag: '0',
      activeIndex2: '0',
      authority: true,
      username: '',
      staffName: ''
    };
  },
  created() {
    let phone = this.$route.query.dp;
   
    // let url = 'http://172.168.0.140:8080/#/index?dp=TUX1bHOmt8Se6YRs6TfdGxK5ZsI4bePNIiyytu0drQQ=&spu=4140236003';
    let sj = localStorage.getItem('dpphone');
    if (sj != phone) {
      localStorage.setItem('dpphone', sj);
      /*         location.reload() */
    }
    if (phone) {
      this.loginYae(phone);
    } else {
      console.log('没有电话');
    }
  },

  methods: {
    loginYae(dp) {
      this.$http({
        url: '/loginYae?dp=' + dp,
        method: 'post'
      })
        .then(({ data }) => {
          this.loading = false;
          let res = data;

          if (res.code == 200) {
            let obj = JSON.parse(res.data);
            localStorage.setItem('authority', obj.type);
            this.$cookie.set('token', obj.token);

            this.userName = obj.userName;
            this.$store.commit('myselfName', obj.userName);
            console.log(this.$store.state.myName);
            this.$store.commit('empty');
            this.$store.commit('permissionList', 1);
            this.$store.commit('permissionList', 2);
            this.$store.commit('permissionList', 3);
            this.$store.commit('permissionList', 4);
            this.$store.commit('permissionList', 5);
            this.$store.commit('permissionList', 6);
            this.$store.commit('permissionList', 7);
            this.$store.commit('permissionList', 8);
            this.$store.commit('permissionList', 9);

           
            let url = window.location.href
            let spu = url.match(/[?&]spu=([^&]+)/);
            let spuValue = spu[1];
            sessionStorage.setItem('spu', spuValue);
             // this.$router.push({ path: './index' }); //跳转当前目录ResourceTable路由并传递一个数据
             this.$router.push({ path: './blgl', query: { tab: 'three' } });
            this.$store.commit('bili', 'three');
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              duration: 2000
            });
            this.$router.push('/login');
          }
        })
        .catch((err) => {
          this.loading = false;

          this.$message({
            message: err.message,
            type: 'error',
            duration: 2000
          });
          this.$router.push('/login');
        });
    }
  }
};
</script>
  <style lang="less" scoped>
/deep/ .el-loading-text {
  font-size: 25px;
}
/deep/ .el-loading-spinner i {
  font-size: 50px;
}
</style>